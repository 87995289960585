import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://ellsworth.app.1life.com',
  myoneServer: 'https://ellsworth.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-ellsworth',
  },
  stripePublishableKey:
    'pk_test_517WAN8I6Upoy4vYdDY4YyNaM2bQHskzSWEzGUnoNNCJaLC15PQWK40pTvmerXur4MYXMVMCsAoi0yQPCBsAXrLNF00DrMRauN5',
  recaptchaSiteKey: '6LfyUc4UAAAAALsNwFtQ4iHjxdHJ9QvQ6rHJbX6O',
  launchDarklyClientId: '6050340ca544ec0cd24ff93c',
  googleTagManagerId: 'GTM-5PQD',
};
